import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSection = styled.section`
  background-color: ${(props) => props.$bgColor};
  padding-top: ${(props) => props.$paddingTop};
  padding-bottom: ${(props) => props.$paddingBottom};
  h1 {
    color: ${(props) => props.$h1Color};
    font-size: ${(props) => props.$h1Size} !important;
  }
  h2 {
    color: ${(props) => props.$h2Color};
  }
  h3 {
    color: ${(props) => props.$h3Color};
  }
`;

const Section = ({
  padding,
  children,
  classes,
  idName,
  bgColor,
  paddingTop,
  paddingBottom,
  h1Color,
  h1Size,
  h2Color,
  h3Color,
}) => {
  switch (padding) {
    case 'lg':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative py-24 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    case 'md':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative py-16 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    case 'sm':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative py-8 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    case 'top':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative pt-24 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    case 'bottom':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative pb-24 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    case 'none':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative py-0 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    case 'custom':
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          $paddingTop={paddingTop}
          $paddingBottom={paddingBottom}
          className={`relative ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
    default:
      return (
        <StyledSection
          $bgColor={bgColor}
          $h1Size={h1Size}
          $h1Color={h1Color}
          $h2Color={h2Color}
          $h3Color={h3Color}
          className={`relative py-24 ${classes}`}
          id={idName}
        >
          {children}
        </StyledSection>
      );
  }
};

Section.defaultProps = {
  padding: `lg`,
  classes: ``,
};

Section.propTypes = {
  padding: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Section;
