import React from 'react';

import {
  SectionBuilderBgColor,
  SectionBuilderBgImg,
  SectionBuilderComponent,
} from '.';

const SectionBuilder = ({ block, raw, index }) => {
  const {
    _rawDescription,
    _rawContent,
    bgColorBrand,
    bgColorCustom,
    bgStyle,
    component,
    layout,
    content,
    headline,
    idName,
    paddingBottom,
    paddingTop,
    tagline,
  } = block;

  switch (bgStyle) {
    case 'color':
      return (
        <SectionBuilderBgColor
          bgColorBrand={bgColorBrand}
          bgColorCustom={bgColorCustom}
          component={component}
          content={content}
          headline={headline}
          idName={idName}
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          tagline={tagline}
          _rawDescription={_rawDescription}
          _rawContent={_rawContent}
        >
          <SectionBuilderComponent
            component={component}
            content={content}
            layout={layout}
          />
        </SectionBuilderBgColor>
      );
    case 'image':
      return (
        <SectionBuilderBgImg
          bgColorBrand={bgColorBrand}
          bgColorCustom={bgColorCustom}
          component={component}
          content={content}
          headline={headline}
          idName={idName}
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          tagline={tagline}
          _rawDescription={_rawDescription}
          _rawContent={_rawContent}
        >
          <SectionBuilderComponent
            component={component}
            content={content}
            layout={layout}
          />
        </SectionBuilderBgImg>
      );
    default:
      return (
        <SectionBuilderBgColor
          bgColorBrand={bgColorBrand}
          bgColorCustom={bgColorCustom}
          component={component}
          content={content}
          headline={headline}
          idName={idName}
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          tagline={tagline}
          _rawDescription={_rawDescription}
          _rawContent={_rawContent}
        >
          <SectionBuilderComponent
            component={component}
            content={content}
            layout={layout}
          />
        </SectionBuilderBgColor>
      );
  }
};

export default SectionBuilder;
