import React from 'react';

import { ServicesImages, Services2ColImgList } from '.';

const Services = ({ block, raw, index }) => {
  const { layout, template, header, tagline, service, title, image, link } =
    block;

  switch (template) {
    case 'images':
      return (
        <ServicesImages
          block={block}
          raw={raw}
          index={index}
          layout={layout}
          header={header}
          service={service}
          title={title}
          image={image}
          link={link}
        />
      );
    case 'imageList':
      return (
        <Services2ColImgList
          block={block}
          raw={raw}
          index={index}
          layout={layout}
          header={header}
          service={service}
          title={title}
          image={image}
          link={link}
        />
      );
    default:
      return (
        <ServicesImages
          block={block}
          raw={raw}
          index={index}
          layout={layout}
          header={header}
          service={service}
          title={title}
          image={image}
          link={link}
        />
      );
  }
};

export default Services;
