import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const useSanitySettings = () => {
  const data = useStaticQuery(graphql`
    query useSanitySettingsQ {
      sanityBusinessSeo {
        indexStatus
        title
        description
        author
        image {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      sanityBusinessInfo {
        address
        addressUrl
        embedUrl
        emailAddress
        emailUrl
        established
        hours
        hoursUrl
        hoursMon
        hoursTue
        hoursWed
        hoursThu
        hoursFri
        hoursSat
        hoursSun
        name
        owner
        phone
        phoneUrl
        websiteUrl
      }
      sanityBusinessLogo {
        navbar {
          alt
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              formats: WEBP
              layout: FIXED
              height: 70
            )
          }
        }
        footer {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        favicon {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      allSanitySocial {
        nodes {
          _id
          anchor
          url
          icon
        }
      }
      sanityNavbars {
        primaryNavBgColor {
          color
        }
        primaryNavBgColorCustom
        primaryNavTextColor {
          color
        }
        secondaryNavBgColor {
          color
        }
        secondaryNavBgColorCustom
        secondaryNavTextColor {
          color
        }
        primaryNavCtaButtonLabel
        primaryNavCtaButtonLink {
          anchor
          slug {
            current
          }
        }
      }
      allSanityNavbars {
        nodes {
          layout
          primaryNavBgColor {
            color
          }
          primaryNavBgColorCustom
          primaryNavTextColor {
            color
          }
          secondaryNavBgColor {
            color
          }
          secondaryNavBgColorCustom
          secondaryNavTextColor {
            color
          }
          primaryNavCtaButtonLabel
          primaryNavCtaButtonLink {
            slug {
              current
            }
          }
          socialLinks {
            anchor
            icon
            url
          }
          navItem {
            type
            singlePageLink {
              pageDescription
              anchor
              slug {
                current
              }
            }
            dropdownTextLabel
            dropdowns {
              type
              singlePageLink {
                pageDescription
                anchor
                slug {
                  current
                }
                pageBuilder {
                  ... on SanityHero {
                    _key
                    _type
                    bgImg {
                      alt
                      asset {
                        _id
                        gatsbyImageData(placeholder: BLURRED, formats: WEBP)
                      }
                    }
                  }
                }
              }
              dropdownTextLabel
              dropdowns {
                type
                singlePageLink {
                  pageDescription
                  anchor
                  slug {
                    current
                  }
                }
                dropdownTextLabel
              }
            }
          }
        }
      }
      allSanityFooters {
        nodes {
          id
          layout
          tagline
          bgImg {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          socialLinks {
            id
            anchor
            url
            icon
          }
          serviceListSource
        }
      }
      sanityColorsAccent {
        dark {
          color
        }
        darker {
          color
        }
        default {
          color
        }
        light {
          color
        }
        lighter {
          color
        }
      }
      sanityColorsHero {
        headline {
          color
        }
        overlay {
          color
        }
        tagline {
          color
        }
      }
      sanityColorsNeutral {
        dark {
          color
        }
        darker {
          color
        }
        default {
          color
        }
        light {
          color
        }
        lighter {
          color
        }
        white {
          color
        }
        black {
          color
        }
      }
      sanityColorsPrimary {
        dark {
          color
        }
        darker {
          color
        }
        default {
          color
        }
        light {
          color
        }
        lighter {
          color
        }
      }
      sanityColorsSecondary {
        dark {
          color
        }
        darker {
          color
        }
        default {
          color
        }
        light {
          color
        }
        lighter {
          color
        }
      }
      allSanityForms {
        nodes {
          id
          layout
          headline
          _rawTagline
        }
      }
      allSanityPage(sort: { fields: order, order: ASC }) {
        nodes {
          pageType
          addToFooter
          anchor
          order
          slug {
            current
          }
        }
      }
      allSanityProduct {
        nodes {
          _rawDescription
          name
          shortDescription
          link {
            slug {
              current
            }
          }
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
      allSanityService(sort: { fields: order, order: ASC }) {
        nodes {
          id
          _rawDescription
          name
          tag {
            title
          }
          order
          shortDescription
          link {
            slug {
              current
            }
          }
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
      allSanityProject(sort: { fields: date, order: ASC }) {
        nodes {
          _rawDescription
          date(formatString: "MMMM D, YYYY")
          image {
            alt
            asset {
              gatsbyImageData
            }
          }
          link {
            anchor
            slug {
              current
            }
          }
          location
          name
          service {
            name
            link {
              anchor
              slug {
                current
              }
            }
          }
          shortDescription
        }
      }
      allSanityCaseStudy {
        nodes {
          _rawDescription
          name
          shortDescription
          link {
            slug {
              current
            }
          }
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
      allSanityReview {
        nodes {
          _rawDescription
          name
          shortDescription
          link {
            slug {
              current
            }
          }
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
      allSanityEvent {
        nodes {
          _rawDescription
          name
          shortDescription
          link {
            slug {
              current
            }
          }
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
      allSanityGallery {
        nodes {
          title
          tag {
            title
          }
          image {
            alt
            asset {
              url
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          _rawCaption
        }
      }
      # allGooglePagesSheet(sort: { fields: cityStateZip, order: ASC }) {
      #   nodes {
      #     cityStateZip
      #     cityStateSlug
      #   }
      # }
    }
  `);

  // const metadata = data.sanityBusinessMetadata;
  const accent = data.sanityColorsAccent;
  // const asset = data.allSanityAsset.nodes;
  const caseStudies = data.allSanityCaseStudy.nodes;
  const events = data.allSanityEvent.nodes;
  const footers = data.allSanityFooters.nodes;
  const forms = data.allSanityForms.nodes;
  const gallery = data.allSanityGallery.nodes;
  const hero = data.sanityColorsHero;
  const info = data.sanityBusinessInfo;
  // const locations = data.allGooglePagesSheet.nodes;
  const logo = data.sanityBusinessLogo;
  const navbar = data.sanityNavbars;
  const navbars = data.allSanityNavbars.nodes;
  const neutral = data.sanityColorsNeutral;
  const pages = data.allSanityPage.nodes;
  const primary = data.sanityColorsPrimary;
  const products = data.allSanityProduct.nodes;
  const projects = data.allSanityProject.nodes;
  const reviews = data.allSanityReview.nodes;
  const secondary = data.sanityColorsSecondary;
  const services = data.allSanityService.nodes;
  const siteSEO = data.sanityBusinessSeo;
  const socials = data.allSanitySocial.nodes;

  return {
    info,
    // logos,
    // locations,
    // metadata,
    accent,
    footers,
    forms,
    gallery,
    hero,
    logo,
    navbars,
    navbar,
    neutral,
    pages,
    primary,
    secondary,
    siteSEO,
    socials,
    products,
    services,
    projects,
    caseStudies,
    reviews,
    events,
  };
};

export default useSanitySettings;
