import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  SanityBlockContent,
  Section,
  Container,
  Grid,
  Col,
  AnchorText,
  SectionHeader,
} from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const StyledIcon = styled.div`
  color: ${(props) => props.$color};
`;

const StyledAuthor = styled.p`
  color: ${(props) => props.$color};
`;

const DynamicFeedGallery = ({
  block,
  raw,
  index,
  layout,
  tag,
  media,
  idName,
  header,
  _rawDescription,
}) => {
  const { primary, secondary, accent, neutral, hero, gallery } = useSanity();
  const images = gallery?.map(({ image, _rawCaption, title }) => ({
    thumb: image?.asset?.gatsbyImageData,
    full: image?.asset?.gatsbyImageData,
    thumbAlt: image?.alt,
    title,
    caption: _rawCaption,
  }));

  return (
    <Section
      idName="image-gallery"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
    >
      <Container
        padding="none"
        classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl"
        inViewConfigRootMargin="1500px"
      >
        <SectionHeader
          tagline={header?.tagline}
          headline={header?.headline}
          description={header?._rawDescription}
        />
        <div className="mt-24 columns-1 md:columns-3 lg:columns-3">
          {images?.map((image, index) => (
            <Col inViewConfigRootMargin="500px 0px" key={index} classes="mb-4">
              <div>
                <GatsbyImage
                  image={image?.full}
                  alt={image?.thumbAlt}
                  className="transform rounded-xl shadow-xl transition duration-500 hover:z-10 hover:scale-150 hover:rounded-2xl hover:border-4 hover:border-slate-300 hover:shadow-2xl"
                />
                <p className="legend">{image?.caption}</p>
              </div>
            </Col>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default DynamicFeedGallery;
