import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import { SanityBlockContent, Section, Container, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const AccessibilityPolicyDefault = ({ block, raw, index, idName, header }) => {
  const { info, primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <StyledSection
      idName={idName}
      padding="custom"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
      classes="pt-[13rem] lg:pt-[15rem] pb-24"
    >
      <Container
        initialOpacity={1}
        initialScale={1}
        classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl"
      >
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {info?.name}
        </StyledTagline>
        <h1 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-3xl">
          Our Accessibility Policy
        </h1>
        <Container initialOpacity={1} initialScale={1} type="sm">
          <div className="space-y-8 text-left lg:max-w-3xl mx-auto mt-12">
            <p>
              {info?.name} is committed to ensuring digital accessibility for
              people with disabilities. We are continually improving the user
              experience for everyone, and applying the relevant accessibility
              standards.
            </p>
            <h3 className="font-bold capitalize">
              Measures to support accessibility
            </h3>
            <p>
              {info?.name} takes the following measures to ensure accessibility
              of our website (&quot;
              <a
                className="italic"
                href={info?.website}
                target="_blank"
                rel="noreferrer"
              >
                {info?.website}
              </a>
              &quot;):
            </p>
            <ul className="sanity-ul">
              <li>Include accessibility as part of our mission statement.</li>
              <li>Integrate accessibility into our procurement practices.</li>
              <li>Integrate accessibility into our procurement practices.</li>
              <li>Provide continual accessibility training for our staff.</li>
              <li>Include people with disabilities in our design personas.</li>
            </ul>
            <h3 className="font-bold capitalize">Conformance status</h3>
            <p>
              The{' '}
              <a
                target="_blank"
                href="https://www.w3.org/WAI/standards-guidelines/wcag/"
                rel="noreferrer"
              >
                Web Content Accessibility Guidelines (WCAG)
              </a>{' '}
              defines requirements for designers and developers to improve
              accessibility for people with disabilities. It defines three
              levels of conformance: Level A, Level AA, and Level AAA.{' '}
              {info?.name} is partially conformant with WCAG 2.1 level AA.
              Partially conformant means that some parts of the content do not
              fully conform to the accessibility standard.
            </p>
            <h4 className="py-5 font-bold capitalize">
              Additional accessibility considerations
            </h4>
            <p>
              Although our goal is WCAG 2.1 Level AA conformance, we have also
              applied some Level AAA Success Criteria: Images of text are only
              used for decorative purposes. Re-authentication after a session
              expires does not cause loss of data. Some videos have sign
              language interpretation.
            </p>
            <h3 className="font-bold">Feedback</h3>
            <p>
              We welcome your feedback on the accessibility of our website.
              Please let us know if you encounter accessibility barriers on{' '}
              <a
                className="italic"
                href={info?.website}
                target="_blank"
                rel="noreferrer"
              >
                {info?.website}
              </a>
              .
            </p>
            <ul className="sanity-ul">
              <li>
                Phone:{' '}
                <a href={info?.phoneUrl} target="_blank" rel="noreferrer">
                  {info?.phone}
                </a>
              </li>
              <li>
                Email:{' '}
                <a href={info?.emailUrl} target="_blank" rel="noreferrer">
                  {info?.emailAddress}
                </a>
              </li>
              <li>
                Address:{' '}
                <a href={info?.addressUrl} target="_blank" rel="noreferrer">
                  {info?.address}
                </a>
              </li>
            </ul>
            <p>We try to respond to feedback within 5 business days.</p>
            <h3 className="font-bold capitalize">
              Compatibility with browsers and assistive technology
            </h3>
            <p>
              {info?.name}' website is designed to be compatible with the
              following assistive technologies:
            </p>
            <ul className="sanity-ul">
              <li>
                &quot;browser X with assistive technology Y on operating system
                Z&quot;
              </li>
            </ul>
            <p>{info?.name}' website is not compatible with:</p>
            <ul className="sanity-ul">
              <li>
                &quot;browsers older than 3 major versions&quot; or &quot;Mobile
                operating systems older than 5 year&quot;
              </li>
            </ul>
            <h3 className="font-bold capitalize">Technical specifications</h3>
            <p>
              Accessibility of {info?.name} relies on the following technologies
              to work with the particular combination of web browser and any
              assistive technologies or plugins installed on your computer:
            </p>
            <ul className="sanity-ul">
              <li>HTML</li>
              <li>WAI-ARIA</li>
              <li>CSS</li>
              <li>JavaScript</li>
            </ul>
            <h3 className="font-bold capitalize">
              Limitations and alternatives
            </h3>
            <p>
              Despite our best efforts to ensure accessibility of {info?.name},
              there may be some limitations. Below is a description of known
              limitations, and potential solutions. Please contact us if you
              observe an issue not listed below.
            </p>
            <p>Known limitations for {info?.name}' website:</p>
            <ol className="sanity-ol">
              <li>
                <span className="font-bold">Comments from users</span>: uploaded
                images may not have text alternatives because we cannot ensure
                the quality of contributions. We monitor user comments and
                typically repair issues within 10 business days. Please use the
                'report issue' button if you encounter an issue.
              </li>
              <li>
                <span className="font-bold">Archived documents</span>: might not
                work with current assistive technologies because they use
                outdated technologies that do not support accessibility. We
                convert documents to new formats upon request within 3 business
                days. Please contact documents@example.org for support.
              </li>
            </ol>
            <h3 className="font-bold capitalize">Assessment approach</h3>
            <p>
              {info?.name} assessed the accessibility of our website by the
              following approaches:
            </p>
            <ul className="sanity-ul">
              <li>External evaluation</li>
            </ul>
            <h3 className="font-bold capitalize">
              Formal approval of this accessibility statement
            </h3>
            <p>This Accessibility Statement is approved by:</p>
            <ul className="list-inside">
              <li>{info?.name}</li>
              <li className="italic">Communication Department</li>
              <li className="italic">Director of Communication</li>
            </ul>
            <h3 className="font-bold capitalize">Formal complaints</h3>
            <p>
              We aim to respond to accessibility feedback within 5 business
              days, and to propose a solution within 10 business days. You are
              entitled to escalate a complaint to the national authority, should
              you be dissatisfied with our response to you.
            </p>
          </div>
        </Container>
      </Container>
    </StyledSection>
  );
};

export default AccessibilityPolicyDefault;
