import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { SanityBlockContent, Section, Container, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
  padding-top: ${(props) => props.$paddingTop};
  padding-bottom: ${(props) => props.$paddingBottom};
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const StyledIcon = styled.div`
  color: ${(props) => props.$color};
`;

const StyledAuthor = styled.p`
  color: ${(props) => props.$color};
`;

const SectionBuilderComponentGrid = ({ content, layout }) => {
  const { primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <Grid
      classes={
        layout === 'col-1'
          ? 'mt-28 gap-3 sm:grid-cols-1 lg:grid-cols-1'
          : layout === 'col-2'
          ? 'mt-28 gap-x-3 gap-y-12 sm:grid-cols-2 lg:grid-cols-2'
          : layout === 'col-3'
          ? 'mt-28 gap-3 sm:grid-cols-3 lg:grid-cols-3'
          : layout === 'col-4'
          ? 'mt-28 gap-3 sm:grid-cols-3 lg:grid-cols-4'
          : layout === 'col-5'
          ? 'mt-28 gap-3 sm:grid-cols-3 lg:grid-cols-5'
          : layout === 'col-6'
          ? 'mt-28 gap-3 sm:grid-cols-3 lg:grid-cols-6'
          : 'mt-28 gap-3 sm:grid-cols-3 lg:grid-cols-3'
      }
    >
      {content.map((item) => (
        <Col
          key={item?._key}
          classes={
            layout === 'col-1'
              ? 'pt-6 px-4 sm:px-6 lg:px-6'
              : layout === 'col-2'
              ? 'pt-6 px-4 sm:px-6 lg:px-6'
              : layout === 'col-3'
              ? 'pt-6 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
              : layout === 'col-4'
              ? 'pt-6 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
              : layout === 'col-5'
              ? 'pt-6 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
              : layout === 'col-6'
              ? 'pt-6 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
              : 'pt-6 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
          }
        >
          <Container padding="none">
            <GatsbyImage
              image={item?.image?.asset?.gatsbyImageData}
              className="aspect-w-12 aspect-h-7 w-full h-full object-cover rounded-xl shadow-2xl transition hover:scale-105"
              alt={item?.image?.alt}
              loading="lazy"
            />
          </Container>
          <div className="relative z-10">
            <h3 className="mt-8 text-center text-3xl font-medium lg:px-0">
              {item?.headline}
            </h3>
            <p className="mt-5 mb-8 text-left text-base text-zinc-500">
              <SanityBlockContent blocks={item?._rawDescription} />
            </p>
          </div>
        </Col>
      ))}
    </Grid>
  );
};

export default SectionBuilderComponentGrid;
