import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import SanityImage from 'gatsby-plugin-sanity-image';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { AnchorText, Grid, Col } from '.';
import { useSanity } from '../hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const StyledDisclosureButton = styled((props) => (
  <Disclosure.Button {...props} />
))`
  background-color: ${(props) => props.$bgColor} !important;
  &:hover {
    background-color: ${(props) => props.$bgColorHover} !important;
  }
  & h3 {
    color: ${(props) => props.$color} !important;
    &:hover {
      color: ${(props) => props.$colorHover} !important;
    }
  }
`;

const StyledChevronUpIcon = styled((props) => <ChevronUpIcon {...props} />)`
  color: ${(props) => props.$color} !important;
  &:hover {
    color: ${(props) => props.$colorHover} !important;
  }
`;

const StyledCheckIcon = styled.i`
  color: ${(props) => props.$color} !important;
  &:hover {
    color: ${(props) => props.$colorHover} !important;
  }
`;

const SanityBlockContent = ({
  blocks,
  classes,
  initialOpacity,
  initialScale,
}) => {
  const { primary, secondary, accent, neutral, hero, locations } = useSanity();

  const serializers = {
    types: {
      imageAlt: (props) => (
        <div className="pt-6">
          <SanityImage
            {...props.node}
            alt={props.node.alt}
            loading="lazy"
            className="aspect-2 h-full w-full object-cover rounded-xl shadow-2xl transition hover:scale-105"
          />
        </div>
      ),
      accordion: (props) => (
        <div className="w-full p-2 mx-auto rounded-2xl">
          <Disclosure>
            {({ open }) => (
              <div>
                <StyledDisclosureButton
                  $color={neutral?.white?.color}
                  $colorHover={neutral?.white?.color}
                  $bgColor={primary?.dark?.color}
                  $bgColorHover={primary?.darker?.color}
                  className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                >
                  <h3 className="text-xl font-normal normal-case">
                    {props?.node?.headline}
                  </h3>
                  <StyledChevronUpIcon
                    $color={neutral?.white?.color}
                    $colorHover={neutral?.white?.color}
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 mt-1`}
                  />
                </StyledDisclosureButton>
                <Disclosure.Panel className="px-4 pt-4 pb-2">
                  <SanityBlockContent blocks={props?.node?.description} />
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </div>
      ),
      serviceAreas: (props) => (
        <div className="w-full p-2 mx-auto rounded-2xl">
          <Disclosure>
            {({ open }) => (
              <div>
                <StyledDisclosureButton
                  $color={neutral?.white?.color}
                  $colorHover={neutral?.white?.color}
                  $bgColor={primary?.dark?.color}
                  $bgColorHover={primary?.darker?.color}
                  className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                >
                  <h3 className="text-xl font-normal normal-case">
                    {props.node.headline}
                  </h3>
                  <StyledChevronUpIcon
                    $color={neutral?.white?.color}
                    $colorHover={neutral?.white?.color}
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 mt-1`}
                  />
                </StyledDisclosureButton>
                <Disclosure.Panel className="px-4 pt-4 pb-2">
                  <ul className="sanity-ul text-left columns-1 lg:columns-2">
                    {locations.map((location) => (
                      <li key={location?.cityStateSlug}>
                        <AnchorText
                          type="internal"
                          color={neutral?.default?.color}
                          colorHover={accent?.default?.color}
                          to={location?.cityStateSlug}
                          className="text-base"
                        >
                          {location?.cityStateZip}
                        </AnchorText>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </div>
      ),
      grid: (props) => (
        <div>
          <Grid classes="my-8 gap-x-3 gap-y-12 sm:grid-cols-2 lg:grid-cols-2">
            <Col classes="">
              <div className="relative z-10">
                <p className="">
                  <SanityBlockContent blocks={props?.node?.col1} />
                </p>
              </div>
            </Col>
            <Col classes="">
              <div className="relative z-10">
                <p className="">
                  <SanityBlockContent blocks={props?.node?.col2} />
                </p>
              </div>
            </Col>
          </Grid>
        </div>
      ),
    },
    styles: (props) => {
      const { type } = props;
      const quote = type === 'blockquote';
      if (quote) {
        return (
          <blockquote className="border border-l-2 border-gray-500">
            {props.children}
          </blockquote>
        );
      }
    },
    list: (props) => {
      const { type } = props;
      const bullet = type === 'bullet';
      const number = type === 'number';
      const check = type === 'check';
      const checkCols = type === 'checkCols';
      if (bullet) {
        return <ul className="sanity-ul text-left">{props.children}</ul>;
      } else if (number) {
        return <ol className="sanity-ol text-left">{props.children}</ol>;
      } else if (check) {
        return <ul className="fa-ul text-left">{props.children}</ul>;
      } else if (checkCols) {
        return <ul className="fa-ul text-left columns-2">{props.children}</ul>;
      } else {
        return <ul className="sanity-ul text-left">{props.children}</ul>;
      }
    },
    listItem: (props) => {
      const { node } = props;
      // const bullet = type === 'bullet';
      // const number = type === 'number';
      const check = node.listItem === 'check';
      const checkCols = node.listItem === 'checkCols';
      // if (bullet) {
      //   return <li className="">{props.children}</li>;
      // } else if (number) {
      //   return <li className="">{props.children}</li>;
      if (check) {
        return (
          <li className="">
            <span className="fa-li">
              <StyledCheckIcon
                $color={accent.default.color}
                $colorHover={accent.default.color}
                className="fas fa-check"
              ></StyledCheckIcon>
            </span>
            {props.children}
          </li>
        );
      } else if (checkCols) {
        return (
          <li className="">
            <span className="fa-li">
              <StyledCheckIcon
                $color={accent.default.color}
                $colorHover={accent.default.color}
                className="fas fa-check"
              ></StyledCheckIcon>
            </span>
            {props.children}
          </li>
        );
      } else {
        return <li className="">{props.children}</li>;
      }
    },
    marks: {
      highlight: ({ children }) => (
        <span className="font-bold bg-yellow-400 p-1">{children}</span>
      ),
      underline: ({ children }) => (
        <span className="underline">{children}</span>
      ),
      strikeThrough: ({ children }) => (
        <span className="line-through">{children}</span>
      ),
      textXs: ({ children }) => (
        <span className="text-xs lg:!text-xs">{children}</span>
      ),
      textSm: ({ children }) => (
        <span className="text-sm lg:!text-sm">{children}</span>
      ),
      textMd: ({ children }) => (
        <span className="text-base lg:!text-base">{children}</span>
      ),
      textLg: ({ children }) => (
        <span className="text-lg lg:!text-lg">{children}</span>
      ),
      textXl: ({ children }) => (
        <span className="text-lg lg:!text-xl">{children}</span>
      ),
      text2Xl: ({ children }) => (
        <span className="text-xl lg:!text-2xl">{children}</span>
      ),
      text3Xl: ({ children }) => (
        <span className="text-2xl lg:!text-3xl">{children}</span>
      ),
      text4Xl: ({ children }) => (
        <span className="text-3xl lg:!text-4xl">{children}</span>
      ),
      text5Xl: ({ children }) => (
        <span className="text-4xl lg:!text-5xl">{children}</span>
      ),
      text6Xl: ({ children }) => (
        <span className="text-5xl lg:!text-6xl">{children}</span>
      ),
      text7Xl: ({ children }) => (
        <span className="text-6xl lg:!text-7xl">{children}</span>
      ),
      text8Xl: ({ children }) => (
        <span className="text-7xl lg:!text-8xl">{children}</span>
      ),
      text9Xl: ({ children }) => (
        <span className="text-8xl lg:!text-9xl">{children}</span>
      ),
      cols2: ({ children }) => (
        <div className="prose-ul:columns-2">{children}</div>
      ),
      internalLink: ({ mark, children }) => {
        const { reference = {} } = mark;
        const href = reference?.slug?.current;
        return (
          <AnchorText
            type="internal"
            to={href}
            color={accent?.default?.color}
            colorHover={accent?.dark?.color}
          >
            {children}
          </AnchorText>
        );
      },
      externalLink: ({ mark, children }) => {
        const { blank, href } = mark;
        return blank ? (
          <AnchorText
            type="external"
            href={href}
            color={accent?.default?.color}
            colorHover={accent?.dark?.color}
          >
            {children}
          </AnchorText>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };

  const variants = {
    initial: { opacity: initialOpacity, scale: initialScale },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 0,
      },
    },
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('animate');
    }
    if (!inView) {
      controls.start('initial');
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="initial"
      animate={controls}
      className={`space-y-6 ${classes}`}
    >
      <BlockContent
        blocks={blocks}
        serializers={serializers}
        className={`space-y-6 sanity-headlines ${classes}`}
      />
    </motion.div>
  );
};

SanityBlockContent.defaultProps = {
  classes: '',
  initialOpacity: 0.1,
  initialScale: 0.8,
};

SanityBlockContent.propTypes = {
  blocks: PropTypes.array.isRequired,
  classes: PropTypes.string,
  initialOpacity: PropTypes.number,
  initialScale: PropTypes.number,
};

export default SanityBlockContent;
