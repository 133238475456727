import React from 'react';

import { ThankYouMessageDefault } from '.';

const ThankYouMessages = ({ block, raw, index }) => {
  const { message, idName, header } = block;

  switch (message) {
    case 'default':
      return (
        <ThankYouMessageDefault
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
        />
      );
    default:
      return (
        <ThankYouMessageDefault
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
        />
      );
  }
};

export default ThankYouMessages;
