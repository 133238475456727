import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import {
  SanityBlockContent,
  Section,
  Container,
  Grid,
  Col,
  AnchorText,
} from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const PageMissingMessageDefault = ({ block, raw, index, idName, header }) => {
  const { info, primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <StyledSection
      idName={idName}
      padding="custom"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
      classes="pt-[13rem] lg:pt-[15rem] pb-24"
    >
      <Container
        initialOpacity={1}
        initialScale={1}
        classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl"
      >
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {info?.name}
        </StyledTagline>
        <h1 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-3xl">
          404 Page Missing!
        </h1>
        <Container initialOpacity={1} initialScale={1} type="sm">
          <div className="text-center text-lg lg:text-xl space-y-2 lg:max-w-3xl mx-auto mt-12">
            <p className="font-bold px-12 lg:px-0">
              Looks like there's a problem with the URL entered.
            </p>
            <p>
              Please check the spelling or visit our{' '}
              <AnchorText type="internal" to="home">
                home page
              </AnchorText>
              .{' '}
            </p>
          </div>
        </Container>
      </Container>
    </StyledSection>
  );
};

export default PageMissingMessageDefault;
