import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  SanityBlockContent,
  Section,
  Container,
  Grid,
  Col,
  AnchorText,
} from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const StyledIcon = styled.div`
  color: ${(props) => props.$color};
`;

const StyledAuthor = styled.p`
  color: ${(props) => props.$color};
`;

const DynamicFeedServices = ({
  block,
  raw,
  index,
  layout,
  tag,
  media,
  idName,
  header,
  _rawDescription,
}) => {
  const { primary, secondary, accent, neutral, hero, services } = useSanity();

  return (
    <StyledSection
      idName={idName}
      type="sm"
      bgColor={neutral?.light?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
    >
      <Container classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl">
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {header?.tagline}
        </StyledTagline>
        <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-lg">
          {header?.headline}
        </h2>
        <div className="mt-5 max-w-prose mx-auto text-xl text-zinc-600">
          <SanityBlockContent blocks={header?._rawDescription} />
        </div>
        <Grid
          classes={
            layout === 'col-1'
              ? 'mt-20 gap-3 grid-cols-1 lg:grid-cols-1'
              : layout === 'col-2'
              ? 'mt-20 gap-3 grid-cols-2 lg:grid-cols-2'
              : layout === 'col-3'
              ? 'mt-20 gap-3 grid-cols-2 lg:grid-cols-3'
              : layout === 'col-4'
              ? 'mt-20 gap-3 grid-cols-2 lg:grid-cols-4'
              : layout === 'col-5'
              ? 'mt-20 gap-3 grid-cols-2 lg:grid-cols-5'
              : layout === 'col-6'
              ? 'mt-20 gap-3 grid-cols-2 lg:grid-cols-6'
              : 'mt-20 gap-3 grid-cols-2 lg:grid-cols-3'
          }
        >
          {services
            .filter((service) => tag?.title === service?.tag?.title)
            .map((service) => (
              <AnchorText to={service?.link?.slug?.current}>
                <Col
                  key={service?.name}
                  classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                >
                  <Container padding="none" classes="">
                    <div className="bg-white w-full h-full object-cover rounded-full shadow-2xl transition hover:scale-105">
                      {media === 'icons' ? (
                        <div className="p-6 lg:p-8">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className={
                              layout === 'col-1'
                                ? 'w-full h-full mx-auto object-cover'
                                : layout === 'col-2'
                                ? 'w-full h-full mx-auto object-cover'
                                : layout === 'col-3'
                                ? 'w-full h-full mx-auto object-cover'
                                : layout === 'col-4'
                                ? 'w-full h-full mx-auto object-cover'
                                : layout === 'col-5'
                                ? 'w-full h-full mx-auto object-cover'
                                : layout === 'col-6'
                                ? 'w-full h-full object-cover'
                                : 'w-full h-full object-cover'
                            }
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      ) : media === 'images' ? (
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className={
                              layout === 'col-1'
                                ? 'w-full h-full mx-auto object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                                : layout === 'col-2'
                                ? 'w-full h-full mx-auto object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                                : layout === 'col-3'
                                ? 'w-full h-full mx-auto object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                                : layout === 'col-4'
                                ? 'w-full h-full mx-auto object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                                : layout === 'col-5'
                                ? 'w-full h-full mx-auto object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                                : layout === 'col-6'
                                ? 'w-full h-full object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                                : 'w-full h-full object-cover rounded-full aspect-w-12 aspect-h-12 border-2 border-amber-400'
                            }
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      ) : null}
                    </div>
                  </Container>
                  <div className="relative text-center break-word z-10">
                    <h3
                      className={
                        layout === 'col-1'
                          ? 'mt-8 text-xl lg:text-3xl font-medium'
                          : layout === 'col-2'
                          ? 'mt-8 text-xl lg:text-3xl font-medium'
                          : layout === 'col-3'
                          ? 'mt-8 text-xl lg:text-3xl font-medium'
                          : layout === 'col-4'
                          ? 'mt-8 text-xl lg:text-2xl font-medium'
                          : layout === 'col-5'
                          ? 'mt-8 text-xl lg:text-2xl font-medium'
                          : layout === 'col-6'
                          ? 'mt-8 text-xl lg:text-xl font-medium'
                          : 'mt-8 text-xl lg:text-3xl font-medium'
                      }
                    >
                      {service?.name}
                    </h3>
                    <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                      <SanityBlockContent blocks={service?._rawDescription} />
                    </p>
                  </div>
                </Col>
              </AnchorText>
            ))}
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default DynamicFeedServices;
