import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { SanityBlockContent, Section, Container, Grid, Col, Button } from '.';

const RowTextLeft = ({
  headline,
  _rawDescription,
  ctaType,
  ctaInternalLink,
  ctaExternalLink,
  ctaLabel,
  image,
}) => (
  <Grid classes="lg:grid-cols-2 gap-x-32 gap-y-14 lg:gap-y-36 lg:max-w-7xl lg:h-full">
    <Col classes="order-2 lg:order-none">
      <div className="text-lg text-zinc-500 max-w-prose mx-auto lg:max-w-none space-y-10">
        <div className="space-y-6 text-left">
          <h2 className="text-3xl font-extrabold tracking-wide capitalize">
            {headline}
          </h2>
          <div>
            <SanityBlockContent blocks={_rawDescription} />
          </div>
        </div>
        <div className="flex mt-12">
          <div className="mx-auto lg:mx-0 max">
            <Button
              linkType={ctaType}
              internalLink={ctaInternalLink?.slug?.current}
              externalLink={ctaExternalLink}
              label={ctaLabel}
            />
          </div>
        </div>
      </div>
    </Col>
    <Col classes="order-1 lg:order-none lg:h-full">
      <Container padding="img" classes="lg:sticky lg:top-44">
        <GatsbyImage
          image={image?.asset?.gatsbyImageData}
          className="relative aspect-w-12 aspect-h-7 w-full h-full object-cover rounded-xl shadow-2xl transition hover:scale-105"
          alt={image?.alt}
          loading="lazy"
        />
      </Container>
    </Col>
  </Grid>
);

const RowTextRight = ({
  headline,
  _rawDescription,
  ctaType,
  ctaInternalLink,
  ctaExternalLink,
  ctaLabel,
  image,
}) => (
  <Grid classes="lg:grid-cols-2 gap-x-32 gap-y-14 lg:gap-y-36 lg:max-w-7xl">
    <Col classes="order-1 lg:order-none lg:h-full">
      <Container padding="img" classes="relative lg:sticky lg:top-44">
        <GatsbyImage
          image={image?.asset?.gatsbyImageData}
          className="relative aspect-w-12 aspect-h-7 w-full h-full object-cover rounded-xl shadow-2xl transition hover:scale-105"
          alt={image?.alt}
          loading="lazy"
        />
      </Container>
    </Col>
    <Col classes="order-2 lg:order-none">
      <div className="text-lg text-zinc-500 max-w-prose mx-auto lg:max-w-none space-y-10">
        <div className="space-y-6 text-left">
          <h2 className="text-3xl font-extrabold tracking-wide capitalize">
            {headline}
          </h2>
          <div>
            <SanityBlockContent blocks={_rawDescription} />
          </div>
        </div>
        <div className="flex mt-12">
          <div className="mx-auto lg:mx-0 max">
            <Button
              linkType={ctaType}
              internalLink={ctaInternalLink?.slug?.current}
              externalLink={ctaExternalLink}
              label={ctaLabel}
            />
          </div>
        </div>
      </div>
    </Col>
  </Grid>
);

const SectionBuilderComponentAlternating = ({ content }) => {
  const contentRows = content.map((item, index) =>
    index % 2 === 0 ? (
      <RowTextLeft key={item.name} {...item} />
    ) : (
      <RowTextRight key={item.name} {...item} />
    )
  );

  return <Container classes="mt-28 space-y-32">{contentRows}</Container>;
};

export default SectionBuilderComponentAlternating;
