import React from 'react';

import {
  SectionBuilderComponentGrid,
  SectionBuilderComponentAlternating,
  SectionBuilderComponentTabs,
} from '.';

const SectionBuilderComponent = ({ component, content, layout }) => {
  switch (component) {
    case 'alternating':
      return <SectionBuilderComponentAlternating content={content} />;
    case 'grid':
      return <SectionBuilderComponentGrid content={content} layout={layout} />;
    case 'tabs':
      return <SectionBuilderComponentTabs content={content} />;

    default:
      return <SectionBuilderComponentGrid content={content} />;
  }
};

export default SectionBuilderComponent;
