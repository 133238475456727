import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledAnchorTextInternal = styled((props) => <Link {...props} />)`
  color: ${(props) => props.$color} !important;
  cursor: pointer !important;
  transition: all 0.1s !important;
  font-weight: ${(props) => props.$weight} !important;
  font-size: ${(props) => props.$size} !important;
  line-height: ${(props) => props.$lineHeight} !important;
  &:hover {
    color: ${(props) => props.$colorHover} !important;
    cursor: pointer !important;
    transition: all 0.1s !important;
    font-weight: ${(props) => props.$weightHover} !important;
    font-size: ${(props) => props.$sizeHover} !important;
    line-height: ${(props) => props.$lineHeightHover} !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  i,
  span {
    color: ${(props) => props.$color} !important;
    cursor: pointer !important;
    transition: all 0.1s !important;
    font-weight: ${(props) => props.$weight} !important;
    font-size: ${(props) => props.$size} !important;
    line-height: ${(props) => props.$lineHeight} !important;

    &:hover,
    &:focus {
      color: ${(props) => props.$colorHover} !important;
      cursor: pointer !important;
      transition: all 0.1s !important;
      font-weight: ${(props) => props.$weightHover} !important;
      font-size: ${(props) => props.$sizeHover} !important;
      line-height: ${(props) => props.$lineHeightHover} !important;
    }
    &:active {
      color: ${(props) => props.$colorHover} !important;
      cursor: pointer !important;
      transition: all 0.1s !important;
      font-weight: ${(props) => props.$weightHover} !important;
      font-size: ${(props) => props.$sizeHover} !important;
      line-height: ${(props) => props.$lineHeightHover} !important;
    }
  }
`;

const StyledAnchorTextExternal = styled.a`
  color: ${(props) => props.$color} !important;
  cursor: pointer !important;
  transition: all 0.1s !important;
  font-weight: ${(props) => props.$weight} !important;
  font-size: ${(props) => props.$size} !important;
  line-height: ${(props) => props.$lineHeight} !important;
  &:hover {
    color: ${(props) => props.$colorHover} !important;
    cursor: pointer !important;
    transition: all 0.1s !important;
    font-weight: ${(props) => props.$weightHover} !important;
    font-size: ${(props) => props.$sizeHover} !important;
    line-height: ${(props) => props.$lineHeightHover} !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  i,
  span {
    color: ${(props) => props.$color} !important;
    cursor: pointer !important;
    transition: all 0.1s !important;
    font-weight: ${(props) => props.$weight} !important;
    font-size: ${(props) => props.$size} !important;
    line-height: ${(props) => props.$lineHeight} !important;
    &:hover {
      color: ${(props) => props.$colorHover} !important;
      cursor: pointer !important;
      transition: all 0.1s !important;
      font-weight: ${(props) => props.$weightHover} !important;
      font-size: ${(props) => props.$sizeHover} !important;
      line-height: ${(props) => props.$lineHeightHover} !important;
    }
  }
`;

const AnchorText = function ({
  type,
  to,
  href,
  children,
  classes,
  color,
  colorHover,
  bgColor,
  bgColorHover,
  weight,
  weightHover,
  size,
  sizeHover,
  lineHeight,
  lineHeightHover,
}) {
  switch (type) {
    case 'internal':
      return (
        <StyledAnchorTextInternal
          to={to === 'home' ? '/' : `/${to}/`}
          $color={color}
          $colorHover={colorHover}
          $bgColor={bgColor}
          $bgColorHover={bgColorHover}
          $weight={weight}
          $weightHover={weightHover}
          $size={size}
          $sizeHover={sizeHover}
          $lineHeight={lineHeight}
          $lineHeightHover={lineHeightHover}
          className={classes}
          activeClassName="active"
        >
          {children}
        </StyledAnchorTextInternal>
      );
    case 'external':
      return (
        <StyledAnchorTextExternal
          href={href}
          $color={color}
          $colorHover={colorHover}
          $bgColor={bgColor}
          $bgColorHover={bgColorHover}
          $weight={weight}
          $weightHover={weightHover}
          $size={size}
          $sizeHover={sizeHover}
          $lineHeight={lineHeight}
          $lineHeightHover={lineHeightHover}
          target="_blank"
          rel="noopener"
        >
          {children}
        </StyledAnchorTextExternal>
      );
    default:
      return (
        <StyledAnchorTextInternal
          to={to === 'home' ? '/' : `/${to}/`}
          $color={color}
          $colorHover={colorHover}
          $bgColor={bgColor}
          $bgColorHover={bgColorHover}
          $weight={weight}
          $weightHover={weightHover}
          $size={size}
          $sizeHover={sizeHover}
          $lineHeight={lineHeight}
          $lineHeightHover={lineHeightHover}
          className={classes}
          activeClassName="active"
        >
          {children}
        </StyledAnchorTextInternal>
      );
  }
};

AnchorText.defaultProps = {
  type: 'internal',
  to: '/',
  classes: '',
};

AnchorText.propTypes = {
  type: PropTypes.string,
  to: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default AnchorText;
