import React from 'react';

import {
  AccessibilityPolicies,
  Benefits,
  Contacts,
  Cta,
  CustomSections,
  DynamicFeeds,
  Employees,
  Features,
  Hero,
  HtmlSitemaps,
  Menus,
  PageMissingMessages,
  PrivacyPolicies,
  RichText,
  SectionBuilder,
  Services,
  Spacer,
  Testimonials,
  ThankYouMessages,
} from '.';

const PageBuilder = (props) => {
  const { type, pageBuilder, _rawPageBuilder } = props;
  // Load the right component, based on the _type from Sanity
  const Components = {
    accessibilityPolicies: AccessibilityPolicies,
    benefits: Benefits,
    contacts: Contacts,
    cta: Cta,
    customSection: CustomSections,
    dynamicFeeds: DynamicFeeds,
    employees: Employees,
    features: Features,
    hero: Hero,
    htmlSitemaps: HtmlSitemaps,
    menuSection: Menus,
    pageMissingMessages: PageMissingMessages,
    privacyPolicies: PrivacyPolicies,
    richText: RichText,
    section: SectionBuilder,
    services: Services,
    spacer: Spacer,
    testimonials: Testimonials,
    thankYouMessages: ThankYouMessages,
  };

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block,
        type,
        raw: _rawPageBuilder[index],
      });
    }
  });
};

export default PageBuilder;
