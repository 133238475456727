import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import {
  SanityBlockContent,
  Section,
  Container,
  Grid,
  Col,
  AnchorText,
} from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const HtmlSitemapDefault = ({ block, raw, index, idName, header }) => {
  const { info, primary, secondary, accent, neutral, hero, pages } =
    useSanity();

  return (
    <StyledSection
      idName={idName}
      padding="custom"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
      classes="pt-[13rem] lg:pt-[15rem] pb-24"
    >
      <Container
        initialOpacity={1}
        initialScale={1}
        classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-3xl"
      >
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {info?.name}
        </StyledTagline>
        <h1 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-4xl">
          Our Sitemap
        </h1>
        <Container
          initialOpacity={1}
          initialScale={1}
          padding="sm"
          classes="pt-24"
        >
          <Grid classes="lg:grid-cols-2 gap-8 lg:gap-12 text-left">
            <Col classes="space-y-6 bg-white rounded-xl shadow-xl p-6">
              <h3>Company</h3>
              <ul>
                {pages
                  .filter((item) => item?.pageType === 'brand')
                  .map((item) => (
                    <li>
                      <AnchorText type="internal" to={item?.slug?.current}>
                        {item?.anchor}
                      </AnchorText>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col classes="space-y-6 bg-white rounded-xl shadow-xl p-6">
              <h3>Services</h3>
              <ul>
                {pages
                  .filter((item) => item?.pageType === 'service')
                  .map((item) => (
                    <li>
                      <AnchorText type="internal" to={item?.slug?.current}>
                        {item?.anchor}
                      </AnchorText>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col classes="space-y-6 bg-white rounded-xl shadow-xl p-6">
              <h3>Locations</h3>
              <ul>
                {pages
                  .filter((item) => item?.pageType === 'location')
                  .map((item) => (
                    <li>
                      <AnchorText type="internal" to={item?.slug?.current}>
                        {item?.anchor}
                      </AnchorText>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col classes="space-y-6 bg-white rounded-xl shadow-xl p-6">
              <h3>Blog Posts</h3>
              <ul>
                {pages
                  .filter((item) => item?.pageType === 'post')
                  .map((item) => (
                    <li>
                      <AnchorText type="internal" to={item?.slug?.current}>
                        {item?.anchor}
                      </AnchorText>
                    </li>
                  ))}
              </ul>
            </Col>
          </Grid>
        </Container>
      </Container>
    </StyledSection>
  );
};

export default HtmlSitemapDefault;
