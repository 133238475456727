import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/FontAwesomeIconLibrary';

import { AnchorText, Section, Container, Flex, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledContactLinks = styled.a`
  & i {
    color: ${(props) => props.$iconColor};
    transition: all 0.25s;
    &:hover {
      color: ${(props) => props.$iconColorHover};
      transition: all 0.25s;
    }
  }
  & span {
    color: ${(props) => props.$textColor};
    transition: all 0.25s;
    &:hover {
      color: ${(props) => props.$textColorHover};
      transition: all 0.25s;
    }
  }
`;

const StyledSocialLinks = styled.a`
  & path {
    color: ${(props) => props.$iconColor};
    transition: all 0.25s;
    &:hover {
      color: ${(props) => props.$iconColorHover};
      transition: all 0.25s;
    }
  }
  & span {
    color: ${(props) => props.$textColor};
    transition: all 0.25s;
    &:hover {
      color: ${(props) => props.$textColorHover};
      transition: all 0.25s;
    }
  }
`;

const NavbarSecondary = function () {
  const { navbars, primary, secondary, accent, neutral, hero, info } =
    useSanity();

  const nap = [
    {
      icon: 'fas fa-map-marker-alt',
      anchor: info.address,
      url: info.addressUrl,
    },
    {
      icon: 'fas fa-envelope',
      anchor: info.emailAddress,
      url: info.emailUrl,
    },
    {
      icon: 'fas fa-phone',
      anchor: info.phone,
      url: info.phoneUrl,
    },
  ];

  return (
    <Section
      padding="none"
      classes="hidden lg:block z-50 w-full"
      bgColor={primary?.darker?.color}
    >
      <Container padding="sm" classes="max-w-7xl px-4 xl:px-0">
        <Grid classes="lg:grid-cols-3 flex justify-end align-items-center h-12">
          <Flex classes="col-span-1 justify-start items-center">
            {navbars.map((item) =>
              item.socialLinks.map((social) => (
                <Container
                  padding="none"
                  key={social._id}
                  classes="my-auto pl-3"
                >
                  <StyledSocialLinks
                    $iconColor={accent?.default?.color}
                    $iconColorHover={accent?.light?.color}
                    $textColor={neutral?.white?.color}
                    $textColorHover={accent?.light?.color}
                    href={social.url}
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      className="!text-2xl"
                      icon={['fab', social.icon]}
                    />
                    <span className="text-md sr-only font-thin">
                      {social.anchor}
                    </span>
                  </StyledSocialLinks>
                </Container>
              ))
            )}
          </Flex>
          <Flex classes="col-span-2 justify-end items-center">
            {nap.map((item) => (
              <Container
                padding="none"
                key={item.anchor}
                classes="my-auto px-6"
              >
                <StyledContactLinks
                  $iconColor={accent?.default?.color}
                  $iconColorHover={accent?.light?.color}
                  $textColor={neutral?.white?.color}
                  $textColorHover={accent?.light?.color}
                  href={item.url}
                  target="_blank"
                  rel="noopener"
                >
                  <i className={`${item.icon} mr-3`} />
                  <span className="text-md font-thin">{item.anchor}</span>
                </StyledContactLinks>
              </Container>
            ))}
          </Flex>
        </Grid>
      </Container>
    </Section>
  );
};

export default NavbarSecondary;
