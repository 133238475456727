import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { useSanity } from '../hooks';
import { AnchorText, Grid, Col, Container, FormHero } from '.';

const StyledContainer = styled((props) => <Container {...props} />)`
  color: ${(props) => props.$color} !important;
  background-color: ${(props) => props.$bgColor} !important;
  & h2 {
    color: ${(props) => props.$h2Color} !important;
  }
  & h3 {
    color: ${(props) => props.$h3Color} !important;
    :hover {
      color: ${(props) => props.$h3ColorHover} !important;
    }
    & span {
      color: ${(props) => props.$spanColor} !important;
    }
  }
`;

const SidebarListServices = ({ type }) => {
  const { pages, primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <div className="space-y-6 lg:sticky lg:top-44">
      <StyledContainer
        padding="none"
        // bgColor={primary?.dark?.color}
        h2Color={accent?.default?.color}
        h3Color={neutral?.lighter?.color}
        h3ColorHover={accent?.default?.color}
        spanColor={accent?.default?.color}
        // outerClasses="rounded-xl shadow-xl"
        classes="relative p-3 lg:p-5 max-w-4xl mx-3 lg:mx-auto space-y-6"
      >
        <FormHero />
        {/* <h2 className="text-center text-white font-bold">Our Reviews</h2>

        <iframe
          id="msgsndr_reviews"
          src="https://services.leadconnectorhq.com/appengine/reviews/get_widget/qkEaUTZ3nW0D2gkGHSjM"
          frameborder="0"
          scrolling="no"
          className="w-full rounded-xl"
        ></iframe> */}
      </StyledContainer>
    </div>
  );
};

export default SidebarListServices;
