/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import {
  Section,
  Container,
  Col,
  Grid,
  SanityBlockContent,
  AnchorText,
  Overlay,
} from '.';
import { useSanity } from '../hooks';

const StyledHeader = styled.div`
  span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledDiv = styled.div`
  & a h3 span {
    color: ${(props) => props.$color} !important;
  }
  :hover {
    & a h3 span {
      color: ${(props) => props.$colorHover} !important;
    }
  }
`;

const StyledH3 = styled.h3`
  color: ${(props) => props.$color} !important;
  :hover {
    color: ${(props) => props.$colorHover} !important;
  }
`;

const Services2ColImgList = ({ block, raw, index, header, service, image }) => {
  const { primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <Section
      type="my"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
    >
      <Container classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl">
        <StyledHeader $spanColor={accent?.dark?.color}>
          <span className="text-base font-semibold tracking-wider uppercase">
            {header?.tagline}
          </span>
          <h2 className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl mx-auto">
            {header?.headline}
          </h2>
          <div className="mt-5 max-w-prose mx-auto text-xl text-zinc-600">
            <SanityBlockContent blocks={header?._rawDescription} />
          </div>
        </StyledHeader>
        <div className="mt-24">
          <Grid classes="lg:grid-cols-2 gap-24">
            <Col classes="w-full">
              <GatsbyImage
                image={image?.asset?.gatsbyImageData}
                className="w-full h-full object-fit drop-shadow-lg"
                alt={hero?.bgImg?.alt}
              />
            </Col>
            <Col>
              <Grid classes="gap-8">
                {service.map((item) => (
                  <Col key={item?.link?.slug?.current} classes="">
                    <StyledDiv
                      $color={neutral.white.color}
                      $colorHover={accent.default.color}
                    >
                      <AnchorText
                        type="internal"
                        to={item?.link?.slug?.current}
                        classes="relative py-2 px-4 rounded-xl shadow-2xl overflow-hidden lg:px-8 flex md:h-full lg:flex-col justify-between"
                      >
                        {item?.link?.pageBuilder?.map((hero) => (
                          <div className="absolute inset-0">
                            <GatsbyImage
                              image={hero?.bgImg?.asset?.gatsbyImageData}
                              className="w-full h-full object-cover rounded-xl"
                              alt={hero?.bgImg?.alt}
                            />
                            <Overlay
                              bgColor={primary?.default?.color}
                              bgColorHover={primary?.dark?.color}
                            />
                          </div>
                        ))}
                        <div className="relative m-auto text-center z-auto">
                          <h3
                            $color={neutral?.white?.color}
                            $colorHover={accent?.default?.color}
                            className="mb-4 text-4xl font-bold px-3"
                          >
                            <span className="drop-shadow-darker">
                              {item?.link?.anchor}
                            </span>
                          </h3>
                        </div>
                      </AnchorText>
                    </StyledDiv>
                  </Col>
                ))}
              </Grid>
            </Col>
          </Grid>
        </div>
      </Container>
    </Section>
  );
};

export default Services2ColImgList;
