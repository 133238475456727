import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Tab } from '@headlessui/react';

import { SanityBlockContent, Section, Container, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledTabList = styled((props) => <Tab.List {...props} />)`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bgColor};
`;

const StyledTab = styled((props) => <Tab {...props} />)`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bgColor};
  &:hover {
    color: ${(props) => props.$colorHover};
    background-color: ${(props) => props.$bgColorHover};
  }
  &:active {
    color: ${(props) => props.$colorActive} !important;
    background-color: ${(props) => props.$bgColorActive} !important;
  }
  &:focus {
    color: ${(props) => props.$colorFocus};
    background-color: ${(props) => props.$bgColorFocus};
    --tw-ring-offset-color: ${(props) => props.$ringOffsetColorFocus};
  }
`;

const StyledTabSelected = styled((props) => <selected {...props} />)`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bgColor};
  &:hover {
    color: ${(props) => props.$colorHover};
    background-color: ${(props) => props.$bgColorHover};
  }
  &:active {
    color: ${(props) => props.$colorActive};
    background-color: ${(props) => props.$bgColorActive};
  }
  &:focus {
    color: ${(props) => props.$colorFocus};
    background-color: ${(props) => props.$bgColorFocus};
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SectionBuilderComponentTabs = ({ content }) => {
  const { primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <Container padding="none" classes="mt-28">
      <Tab.Group defaultIndex={0}>
        <StyledTabList
          $color={neutral?.white?.color}
          $bgColor={neutral?.default?.color}
          className="flex space-x-1 rounded-xl p-1"
        >
          {content?.map((item) => (
            <StyledTab
              $color={neutral?.white?.color}
              $bgColor={neutral?.dark?.color}
              $colorHover={neutral?.black?.color}
              $bgColorHover={secondary?.default?.color}
              $colorActive={neutral?.white?.color}
              $bgColorActive={accent?.dark?.color}
              $colorFocus={neutral?.white?.color}
              $bgColorFocus={accent?.dark?.color}
              $ringOffsetColorFocus={accent?.darker?.color}
              key={item?.name}
              className="w-full p-2.5 lg:py-2.5 text-xs lg:text-base leading-5 font-semibold rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-opacity-60 break-words"
            >
              {item?.tabName}
            </StyledTab>
          ))}
        </StyledTabList>
        <Tab.Panels>
          {content?.map((item) => (
            <Tab.Panel key={item?.name}>
              <Grid
                padding="none"
                classes="lg:grid-cols-2 gap-y-12 gap-x-24 pt-16"
              >
                <Col classes="space-y-10">
                  <Container padding="none">
                    <GatsbyImage
                      image={item?.image?.asset?.gatsbyImageData}
                      className="aspect-w-12 aspect-h-7 w-full h-full object-cover rounded-xl shadow-2xl transition hover:scale-105"
                      alt={item?.image?.alt}
                      loading="lazy"
                    />
                  </Container>
                </Col>
                <Col classes="space-y-10">
                  <div className="space-y-6 text-left">
                    {/* <h3 className="text-center text-3xl lg:text-left">
                      {item?.headline}
                    </h3> */}
                    <div>
                      <SanityBlockContent
                        blocks={item?._rawDescription}
                        classes="prose-h3:text-3xl"
                      />
                    </div>
                  </div>
                </Col>
              </Grid>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Container>
  );
};

export default SectionBuilderComponentTabs;
