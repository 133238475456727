import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledOverlay = styled.section`
  background-color: ${(props) => props.$bgColor};
  &:hover {
    background-color: ${(props) => props.$bgColorHover};
  }
`;

const Overlay = ({ bgColor, bgColorHover }) => (
  <StyledOverlay
    className="absolute inset-0"
    style={{ mixBlendMode: 'multiply' }}
    aria-hidden="true"
    $bgColor={bgColor}
    $bgColorHover={bgColorHover}
  />
);

export default Overlay;
