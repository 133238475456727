import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { SanityBlockContent, Section, Container, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledBenefits = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const StyledIcon = styled.div`
  color: ${(props) => props.$color};
`;

const StyledAuthor = styled.p`
  color: ${(props) => props.$color};
`;

const BenefitsImages = ({
  block,
  raw,
  index,
  idName,
  header,
  benefit,
  _rawDescription,
}) => {
  const { primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <StyledBenefits
      idName={idName}
      type="sm"
      bgColor={neutral?.light?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
    >
      <Container classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl">
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {header?.tagline}
        </StyledTagline>
        <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-lg">
          {header?.headline}
        </h2>
        <Grid classes="mt-20 gap-3 sm:grid-cols-3 lg:grid-cols-3">
          {benefit.map((item) => (
            <Col
              key={item?._key}
              classes="pt-6 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
            >
              <Container padding="none">
                <GatsbyImage
                  image={item?.graphic?.asset?.gatsbyImageData}
                  className="aspect-w-12 aspect-h-7 w-full h-full object-cover rounded-xl shadow-2xl transition hover:scale-105"
                  alt={item?.graphic?.alt}
                  loading="lazy"
                />
              </Container>
              <div className="relative z-10">
                <h3 className="mt-8 text-3xl font-medium lg:px-8">
                  {item?.title}
                </h3>
                <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                  <SanityBlockContent blocks={item?._rawDescription} />
                </p>
                <StyledAuthor
                  className="mt-5 text-base font-bold"
                  $color={primary?.dark?.color}
                >
                  <span>{item?.author}</span>
                </StyledAuthor>
                <p className="mb-8 text-base font-semibold text-zinc-800">
                  <span>{item?.info}</span>
                </p>
              </div>
            </Col>
          ))}
        </Grid>
      </Container>
    </StyledBenefits>
  );
};

export default BenefitsImages;
