import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  SanityBlockContent,
  SectionBuilderComponentGrid,
  Section,
  Container,
  Grid,
  Col,
} from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
  padding-top: ${(props) => props.$paddingTop};
  padding-bottom: ${(props) => props.$paddingBottom};
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const StyledIcon = styled.div`
  color: ${(props) => props.$color};
`;

const StyledAuthor = styled.p`
  color: ${(props) => props.$color};
`;

const SectionBuilderBgImg = ({
  block,
  raw,
  index,
  _rawDescription,
  _rawContent,
  bgColorBrand,
  bgColorCustom,
  children,
  component,
  content,
  headline,
  idName,
  paddingBottom,
  paddingTop,
  tagline,
}) => {
  const { primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <Section
      idName={idName}
      padding="custom"
      bgColor={!bgColorCustom ? bgColorBrand?.color : bgColorCustom?.hex}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      $spanColor={accent?.light?.color}
      classes="lg:h-full"
    >
      <Container classes="max-w-md px-4 sm:max-w-3xl lg:max-w-7xl lg:h-full">
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold tracking-wider uppercase"
          >
            {tagline}
          </StyledTagline>
          <h2 className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-2xl mx-auto">
            {headline}
          </h2>
          <div className="mt-6 max-w-prose mx-auto text-md lg:text-xl text-zinc-600">
            <SanityBlockContent blocks={_rawDescription} />
          </div>
        </Container>
        {children}
      </Container>
    </Section>
  );
};

export default SectionBuilderBgImg;
